@import './mobile-menu-btn.scss';

.mobile-menu {
  @extend %fixed;
  top: 0;
  left: 0;

  @extend %flex-dc;
  width: 100%;
  height: 100%;
  padding-top: 81px;

  background-color: var(--color--primary_light, #FBF9F4);
  transition: all var(--transition);
  overflow-y: scroll;
  z-index: 6;

  &.isHidden {
    top: -101%;

    button, a {
      pointer-events: none;
      visibility: hidden;
    }
  }
}
.mobile-menu__container {
  padding-top: 32px;
  padding-bottom: 32px;
}
