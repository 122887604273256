:root {
  // ===== fonts-family =====
  --font-family--title: 'Cormorant', serif;
  --font-family--body: 'Raleway', sans-serif;
  // ===== fonts =====
  // --font--subtitle-l: 300 18px / 160% var(--font-family--title);
  // --font--subtitle-r: 400 18px / 160% var(--font-family--title);

  --font--body-small-l: 300 14px / 100% var(--font-family--body);
  --font--body-l: 300 16px / 160% var(--font-family--body);
  --font--body-r: 400 16px / 160% var(--font-family--body);
  --font--label-r: 400 18px / 110% var(--font-family--body);
  --font--link-r: 400 16px / 160% var(--font-family--body);
  // ===== colors =====
  --color--primary_light: #FBF9F4;
  --color--primary_dark: #292E27;
  --color--brand_light: #CBD6C5;
  --color--brand_dark: #41493D;
  --color--brand: #7F8F77;
  --color--gray: #D8DBD7;

  // ===== card set =====
  --items: 1;
  --gap: 12px;
  // ===== other =====
  --border-radius: 14px;
  --transition: 300ms linear;
}

// ===== breakpoints =====
$mobileS: 320px;
$mobileM: 375px;
$mobileL: 425px;
$mobile: 480px;
$tablet: 768px;
$laptop: 1024px;
$desktop: 1200px;
$desktopL: 1440px;
// ===== breakpoints =====
