.header__nav { @include breakpoint-max($desktop - 1) { display: none; } }
.mobile-menu__nav { @include breakpoint-min($desktop) { display: none; } }

.nav__list {
  .header__nav & {
    @extend %flex-ac-jc;
    gap: 14px;
  }

  .mobile-menu__nav & {
    @extend %flex-dc;
    gap: 24px;
  }
}
.nav__item {
  .mobile-menu__nav &:not(:last-of-type) {
    border-bottom: 1px solid var(--color--brand_light, #CBD6C5);
  }
}
.nav__link {
  @extend %relative;
  @extend %flex-ac;
  // height: 56px;
  height: 54px;

  font: var(--font--label-r);
  color: var(--color--primary_dark, #292E27);
  text-decoration: none;

  .header__nav & { padding: 18px 8px; }
  .mobile-menu__nav & { padding: 18px 16px; }
}

.nav__link::before {
  content: '';
  @extend %absolute;
  left: 0;
  bottom: -1px;

  width: 0;
  height: 1px;

  background-color: var(--color--brand, #7F8F77);
  pointer-events: none;
}
.nav__link:hover::before  { width: 100%;}