%flex-ac {
  display: flex;
  align-items: center;
}
%flex-jsb {
  display: flex;
  justify-content: space-between;
}
%flex-ac-jc {
  @extend %flex-ac;
  justify-content: center;
}
%flex-ac-jsb {
  @extend %flex-ac;
  justify-content: space-between;
}
%flex-ac-jfs {
  @extend %flex-ac;
  justify-content: flex-start;
}
%flex-ac-jfe {
  @extend %flex-ac;
  justify-content: flex-end;
}
%flex-dc {
  display: flex;
  flex-direction: column;
}
%flex-ac-dc {
  @extend %flex-ac;
  flex-direction: column;
}
%flex-ac-jc-dc {
  @extend %flex-ac-jc;
  flex-direction: column;
}
%flex-jc {
  display: flex;
  justify-content: center;
}
%flex-ac-ww {
  @extend %flex-ac;
  flex-wrap: wrap;
}
%flex-jc-ww {
  @extend %flex-jc;
  flex-wrap: wrap;
}
%flex-ac-jc-ww {
  @extend %flex-ac-jc;
  flex-wrap: wrap;
}
%flex-100 {
  flex: 0 0 100%;
}
%flex-50 {
  flex: 0 0 50%;
}
%flex-0-0 {
  flex-shrink: 0;
  flex-grow: 0;
}

%relative {
  position: relative;
}
%absolute {
  position: absolute;
}
%fixed {
  position: fixed;
}
%absolute-tlrb {
  @extend %absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
%fixed-tlrb {
  @extend %fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
%top-left-transform-50 {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
%top-right-transform-50 {
  top: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
}

%text-ac {  
  text-align: center;
}
%cursor {
  cursor: pointer;
}
%border-radius {
  border-radius: var(--border-radius);
}
%box-shadow {
  box-shadow : var(--box-shadow);
}
%currentColor {
  fill: currentColor;
}
%transition {
  transition: var(--transition-parallax);
}

%cardSet {
  @extend %flex-jc-ww;
  gap: var(--gap);
}

%cardSet__item {
  flex-basis: calc((100% - var(--gap) * (var(--items) - 1)) / var(--items));
  @include breakpoint-min($tablet) {
    --items: 2;
  }
  @include breakpoint-min($desktop) {
    --items: 4;
  }
}