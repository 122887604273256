@import '/src/common/scss/reset.scss';
@import '/src/common/scss/var.scss';
@import '/src/common/scss/utils.scss';
@import '/src/common/scss/backdrop.scss';
// small components styles
@import '/src/components/loader/loader.scss';
@import '/src/components/btns/btns.scss';
@import '/src/components/logo/logo.scss';
@import '/src/components/nav/nav.scss';
// components styles
@import '/src/components/mobile-menu/mobile-menu.scss';

// * {
//   outline: 1px solid gold;
// }

html {
  scroll-behavior: smooth;
  scroll-padding-top: 81px;
  @include breakpoint-min($desktop) { scroll-padding-top: 67px;}
}

body.isLoading,
body.modalIsOpen,
body.mobileMenuIsOpen {
  overflow: hidden;
}

body {
  @extend %flex-dc;
  min-height: 100vh;

  font: var(--font--body-r);
  font-variant-numeric: lining-nums proportional-nums;
  background-color: var(--color--primary_light, #FBF9F4);
}
main {
  flex-grow: 1;
  margin-top: 81px;
  @include breakpoint-min($desktop) { margin-top: 67px;}
}
footer {
  margin-top: auto;
}

h1, h2, h3 {
  font-family: var(--font-family--title);
  font-variant-numeric: lining-nums proportional-nums;
  font-weight: 400;
}
h1 {
  font-size: 112px;
  line-height: 85%;

  @include breakpoint-min($tablet) { font-size: 128px; }
  // @include breakpoint-min($desktop) { font-size: 144px; }
  @include breakpoint-min($desktop) { font-size: 136px; }
}
h2 {
  font-size: 48px;
  line-height: 90%;

  @include breakpoint-min($tablet) { font-size: 88px; }
  @include breakpoint-min($desktop) { font-size: 100px; }
}
h3 {
  font-size: 42px;
  line-height: 120%;

  @include breakpoint-min($tablet) { font-size: 48px; }
  @include breakpoint-min($desktop) { font-size: 52px; }
}

svg {
  fill: currentColor;
  pointer-events: none;
}
button, a {
  @extend %cursor;
  font-variant-numeric: lining-nums proportional-nums;

  &:focus { outline-color: var(--color--brand, #7F8F77);}
}

.section {
  --section-padding: 68px;
  // --section-padding-top: 48px;
  // --section-padding-bottom: 68px;

  // padding-top: var(--section-padding-top);
  // padding-bottom: var(--section-padding-bottom);
  padding: var(--section-padding) 0;
  
  @include breakpoint-min($tablet) { --section-padding: 80px }
  // @include breakpoint-min($desktop) { --section-padding: 100px }
  @include breakpoint-min($desktopL) { --section-padding: 120px }
}
.container {
  --container-padding: 16px;
  width: 100%;
  padding: 0 var(--container-padding);
  margin: 0 auto;

  @include breakpoint-min($mobile) {
    width: $mobile;
  }
  @include breakpoint-min($tablet) {
    width: $tablet;
    --container-padding: 40px;
  }
  @include breakpoint-min($desktop) {
    width: $desktop;
    --container-padding: 60px;
  }
  @include breakpoint-min($desktopL) {
    width: $desktopL;
    --container-padding: 84px;
  }
}

.subtitle--l, .subtitle--r {
  font-family: var(--font-family--body);
  font-variant-numeric: lining-nums proportional-nums;
  line-height: 160%;
  font-size: 18px;
}
.subtitle--l {
  font-weight: 300;
  @include breakpoint-min($tablet) { font-size: 20px; }
}
.subtitle--r {
  font-weight: 400;
  @include breakpoint-min($desktop) { font-size: 20px; }
}

.list { 
  list-style: none; 
}
.link {
  text-decoration: none;
  color: inherit;
  font: var(--font--body-r);
}
