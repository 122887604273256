.btn-cta {
  @extend %flex-ac-jc;
  min-width: 179px;
  height: 60px;
  padding: 20px 40px;

  outline: none;

  color: var(--color--brand_dark, #41493D);
  font: var(--font--label-r);
  font-variant-numeric: lining-nums proportional-nums;

  border-radius: 32px;
  border: 1px solid var(--color--brand_dark, #41493D);

  &:hover {
    background: var(--color--brand_light, #CBD6C5);
  }
  &:focus {
    border: 6px solid var(--color--brand, #7F8F77);
  }
  &:focus:not(:focus-visible) {
    border: 1px solid var(--color--brand_dark, #41493D);
  }
  &:active {
    color: var(--color--primary_dark, #292E27);
    border: 1px solid var(--color--primary_dark, #292E27);
    background: var(--color--brand, #7F8F77);
  }
  // &:disabled {
  //   color: var(--color--gray, #D8DBD7);
  //   border: 1px solid var(--color--gray, #D8DBD7);
  // }
  // &:visited {
  //   color: var(--color--primary_dark, #292E27);
  //   border: 1px solid var(--color--primary_dark, #292E27);
  //   background: var(--color--brand, #7F8F77);
  // }
}