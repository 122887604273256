.mobile-menu__btn {
  @extend %relative;

  @extend %flex-ac-jc;
  width: 48px;
  height: 48px;
  padding: 8px;
  margin-left: auto;

  color: var(--color--brand_dark);
  background-color: transparent;

  body.mobileMenuIsOpen & { z-index: 20; }

  @include breakpoint-min($desktop) { display: none; }
}
.mobile-menu__btn .burger-line {
  @extend %absolute;

  width: 32px;
  height: 2px;
  margin: 0;

  background-color: currentColor;
  transition-property: height, opacity, transform;
  transition-duration: 0.4s;
}
.burger-line:first-child {
  transform: translateY(8px);
}
.burger-line:last-child {
  transform: translateY(-8px);
}
body.mobileMenuIsOpen .mobile-menu__btn {
  .burger-line:first-child {
    transform: rotate(-45deg);
  }
  .burger-line:nth-child(2n) {
    opacity: 0;
  }
  .burger-line:last-child {
    transform: rotate(45deg);
  }
}
