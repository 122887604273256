.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  @extend %flex-ac-jc;
  padding: 20px 0;

  background-color: rgba(0, 0, 0, 0.9);
  z-index: 3000;
  overflow: scroll;
}