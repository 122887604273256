*, :before, :after {
  box-sizing: border-box;
}

* {
  border: none;
  margin: 0;
  padding: 0;
}

body {
  min-height: 100vh;
  line-height: 1;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

img, picture, video, canvas, svg, iframe {
  max-width: 100%;
  display: block;
}

img {
  vertical-align: top;
}

ol, ul, li {
  list-style: none;
}

input, button, textarea, select {
  font: inherit;
}

button[disabled] {
  cursor: auto;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

:root {
  --font-family--title: "Cormorant", serif;
  --font-family--body: "Raleway", sans-serif;
  --font--body-small-l: 300 14px / 100% var(--font-family--body);
  --font--body-l: 300 16px / 160% var(--font-family--body);
  --font--body-r: 400 16px / 160% var(--font-family--body);
  --font--label-r: 400 18px / 110% var(--font-family--body);
  --font--link-r: 400 16px / 160% var(--font-family--body);
  --color--primary_light: #fbf9f4;
  --color--primary_dark: #292e27;
  --color--brand_light: #cbd6c5;
  --color--brand_dark: #41493d;
  --color--brand: #7f8f77;
  --color--gray: #d8dbd7;
  --items: 1;
  --gap: 12px;
  --border-radius: 14px;
  --transition: .3s linear;
}

.nav__link, .mobile-menu__btn, .header__nav .nav__list, .btn-cta, .backdrop {
  align-items: center;
  display: flex;
}

.mobile-menu__btn, .header__nav .nav__list, .btn-cta, .backdrop {
  justify-content: center;
}

body, .mobile-menu, .mobile-menu__nav .nav__list {
  flex-direction: column;
  display: flex;
}

.mobile-menu__btn, .nav__link {
  position: relative;
}

.mobile-menu__btn .burger-line, .nav__link:before, .visually-hidden {
  position: absolute;
}

.mobile-menu {
  position: fixed;
}

button, a {
  cursor: pointer;
}

.visually-hidden {
  white-space: nowrap;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  border: 0;
  margin: -1px;
  padding: 0;
  overflow: hidden;
}

.backdrop {
  z-index: 3000;
  background-color: #000000e6;
  padding: 20px 0;
  position: fixed;
  inset: 0;
  overflow: scroll;
}

.isHidden .loader__backdrop {
  display: none;
}

.lds-roller {
  width: 80px;
  height: 80px;
  display: inline-block;
  position: relative;
}

.lds-roller div {
  transform-origin: 40px 40px;
  animation: 1.2s cubic-bezier(.5, 0, .5, 1) infinite lds-roller;
}

.lds-roller div:after {
  content: " ";
  width: 7px;
  height: 7px;
  background: var(--color--primary_light);
  border-radius: 50%;
  margin: -4px 0 0 -4px;
  display: block;
  position: absolute;
}

.lds-roller div:nth-child(1) {
  animation-delay: -.036s;
}

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -.144s;
}

.lds-roller div:nth-child(4):after {
  background: var(--color--brand);
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -.18s;
}

.lds-roller div:nth-child(5):after {
  background: var(--color--brand_dark);
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -.216s;
}

.lds-roller div:nth-child(6):after {
  background: var(--color--brand);
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.btn-cta {
  min-width: 179px;
  height: 60px;
  color: var(--color--brand_dark, #41493d);
  font: var(--font--label-r);
  font-variant-numeric: lining-nums proportional-nums;
  border: 1px solid var(--color--brand_dark, #41493d);
  border-radius: 32px;
  outline: none;
  padding: 20px 40px;
}

.btn-cta:hover {
  background: var(--color--brand_light, #cbd6c5);
}

.btn-cta:focus {
  border: 6px solid var(--color--brand, #7f8f77);
}

.btn-cta:focus:not(:focus-visible) {
  border: 1px solid var(--color--brand_dark, #41493d);
}

.btn-cta:active {
  color: var(--color--primary_dark, #292e27);
  border: 1px solid var(--color--primary_dark, #292e27);
  background: var(--color--brand, #7f8f77);
}

.logo {
  font-family: var(--font--title);
  font-size: 32px;
  font-weight: 600;
}

@media screen and (min-width: 768px) {
  .logo {
    font-size: 40px;
    font-weight: 600;
  }
}

@media (max-width: 1199px) {
  .header__nav {
    display: none;
  }
}

@media (min-width: 1200px) {
  .mobile-menu__nav {
    display: none;
  }
}

.header__nav .nav__list {
  gap: 14px;
}

.mobile-menu__nav .nav__list {
  gap: 24px;
}

.mobile-menu__nav .nav__item:not(:last-of-type) {
  border-bottom: 1px solid var(--color--brand_light, #cbd6c5);
}

.nav__link {
  height: 54px;
  font: var(--font--label-r);
  color: var(--color--primary_dark, #292e27);
  text-decoration: none;
}

.header__nav .nav__link {
  padding: 18px 8px;
}

.mobile-menu__nav .nav__link {
  padding: 18px 16px;
}

.nav__link:before {
  content: "";
  width: 0;
  height: 1px;
  background-color: var(--color--brand, #7f8f77);
  pointer-events: none;
  bottom: -1px;
  left: 0;
}

.nav__link:hover:before {
  width: 100%;
}

.mobile-menu__btn {
  width: 48px;
  height: 48px;
  color: var(--color--brand_dark);
  background-color: #0000;
  margin-left: auto;
  padding: 8px;
}

body.mobileMenuIsOpen .mobile-menu__btn {
  z-index: 20;
}

@media (min-width: 1200px) {
  .mobile-menu__btn {
    display: none;
  }
}

.mobile-menu__btn .burger-line {
  width: 32px;
  height: 2px;
  background-color: currentColor;
  margin: 0;
  transition-property: height, opacity, transform;
  transition-duration: .4s;
}

.burger-line:first-child {
  transform: translateY(8px);
}

.burger-line:last-child {
  transform: translateY(-8px);
}

body.mobileMenuIsOpen .mobile-menu__btn .burger-line:first-child {
  transform: rotate(-45deg);
}

body.mobileMenuIsOpen .mobile-menu__btn .burger-line:nth-child(2n) {
  opacity: 0;
}

body.mobileMenuIsOpen .mobile-menu__btn .burger-line:last-child {
  transform: rotate(45deg);
}

.mobile-menu {
  width: 100%;
  height: 100%;
  background-color: var(--color--primary_light, #fbf9f4);
  transition: all var(--transition);
  z-index: 6;
  padding-top: 81px;
  top: 0;
  left: 0;
  overflow-y: scroll;
}

.mobile-menu.isHidden {
  top: -101%;
}

.mobile-menu.isHidden button, .mobile-menu.isHidden a {
  pointer-events: none;
  visibility: hidden;
}

.mobile-menu__container {
  padding-top: 32px;
  padding-bottom: 32px;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 81px;
}

@media (min-width: 1200px) {
  html {
    scroll-padding-top: 67px;
  }
}

body.isLoading, body.modalIsOpen, body.mobileMenuIsOpen {
  overflow: hidden;
}

body {
  min-height: 100vh;
  font: var(--font--body-r);
  font-variant-numeric: lining-nums proportional-nums;
  background-color: var(--color--primary_light, #fbf9f4);
}

main {
  flex-grow: 1;
  margin-top: 81px;
}

@media (min-width: 1200px) {
  main {
    margin-top: 67px;
  }
}

footer {
  margin-top: auto;
}

h1, h2, h3 {
  font-family: var(--font-family--title);
  font-variant-numeric: lining-nums proportional-nums;
  font-weight: 400;
}

h1 {
  font-size: 112px;
  line-height: 85%;
}

@media (min-width: 768px) {
  h1 {
    font-size: 128px;
  }
}

@media (min-width: 1200px) {
  h1 {
    font-size: 136px;
  }
}

h2 {
  font-size: 48px;
  line-height: 90%;
}

@media (min-width: 768px) {
  h2 {
    font-size: 88px;
  }
}

@media (min-width: 1200px) {
  h2 {
    font-size: 100px;
  }
}

h3 {
  font-size: 42px;
  line-height: 120%;
}

@media (min-width: 768px) {
  h3 {
    font-size: 48px;
  }
}

@media (min-width: 1200px) {
  h3 {
    font-size: 52px;
  }
}

svg {
  fill: currentColor;
  pointer-events: none;
}

button, a {
  font-variant-numeric: lining-nums proportional-nums;
}

button:focus, a:focus {
  outline-color: var(--color--brand, #7f8f77);
}

.section {
  --section-padding: 68px;
  padding: var(--section-padding) 0;
}

@media (min-width: 768px) {
  .section {
    --section-padding: 80px;
  }
}

@media (min-width: 1440px) {
  .section {
    --section-padding: 120px;
  }
}

.container {
  --container-padding: 16px;
  width: 100%;
  padding: 0 var(--container-padding);
  margin: 0 auto;
}

@media (min-width: 480px) {
  .container {
    width: 480px;
  }
}

@media (min-width: 768px) {
  .container {
    width: 768px;
    --container-padding: 40px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1200px;
    --container-padding: 60px;
  }
}

@media (min-width: 1440px) {
  .container {
    width: 1440px;
    --container-padding: 84px;
  }
}

.subtitle--l, .subtitle--r {
  font-family: var(--font-family--body);
  font-variant-numeric: lining-nums proportional-nums;
  font-size: 18px;
  line-height: 160%;
}

.subtitle--l {
  font-weight: 300;
}

@media (min-width: 768px) {
  .subtitle--l {
    font-size: 20px;
  }
}

.subtitle--r {
  font-weight: 400;
}

@media (min-width: 1200px) {
  .subtitle--r {
    font-size: 20px;
  }
}

.list {
  list-style: none;
}

.link {
  color: inherit;
  font: var(--font--body-r);
  text-decoration: none;
}

/*# sourceMappingURL=index.245bbec7.css.map */
